import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { requestAsync, mutateAsync } from 'redux-query';
import { checkReferralToken, fetchList } from '../../redux/queries';
import ItemList from '../List/Detail/ItemList';
import PageHeaderWrapper from '../../components/PageHeaderWrapper';
import { compose } from '../../utils';
import { selectValidTokenResponse, selectQuerySuccess, CHECK_REFERRAL_TOKEN, selectList } from '../../selectors';
import style from './ReadOnly.module.scss';

const ReadOnly = ({
  loading = false,
  readOnlyToken,
  dispatchCheckReferralToken,
  dispatchFetchList,
  listId,
  name,
  list,
  isAuthOverrideListView
}) => {
  useEffect(() => {
    dispatchCheckReferralToken(readOnlyToken);
  }, [readOnlyToken]);

  useEffect(() => {
    if (listId) {
      dispatchFetchList(listId);
    }
  }, [listId]);

  const HeaderContent = <div>{`You were invited to view this read only list by ${name}`}</div>;

  if (!isAuthOverrideListView) return null;

  if (isEmpty(list.items)) {
    return <div className={style.emptyListContainer}>There are no items in this list</div>;
  }
  return (
    <Fragment>
      <PageHeaderWrapper title={list.title} content={HeaderContent} />
      <ItemList items={list.items} loading={loading} readOnly />
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  dispatchCheckReferralToken: token => dispatch(mutateAsync(checkReferralToken(token))),
  dispatchFetchList: listId => dispatch(requestAsync(fetchList(listId)))
});

const mapStateToProps = (state, props) => {
  const { listId, name, isAuthOverrideListView } = selectValidTokenResponse(state);

  return {
    listId,
    name,
    isAuthOverrideListView,
    list: selectList(state),
    isAuthenticated: false,
    checkReferralTokenSuccess: selectQuerySuccess(state, CHECK_REFERRAL_TOKEN),
    readOnlyToken: get(props, 'match.params.readOnlyToken', null)
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
);

export default enhance(ReadOnly);
