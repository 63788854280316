import produce from 'immer';
import Api from '../../api';

export const userlistQueries = (Api, produce) => ({
  _produce: produce,
  userListsAdd: (params) => ({
    url: Api.userListsAdd(),
    queryKey: 'userListsAdd',
    options: {
      method: 'POST',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      listUsers: body,
    }),
    update: {
      listUsers: (prev, next) => next,
    },
  }),

  fetchUsersAssociatedWithList: ({ listId }) => ({
    url: Api.fetchUsersForList(),
    queryKey: 'fetchUsersForList',
    options: {
      method: 'POST',
    },
    body: {
      listId,
    },
    force: true,
    transform: (body) => ({
      listUsers: body,
    }),
    update: {
      // TODO: fix this so we dont have to fetch the same listUsers if already exists
      listUsers: (prev, next) => next,
    },
  }),

  fetchListsAssociatedWithCurrentUser: ({ userId }) => ({
    url: Api.fetchListsForUser(),
    queryKey: 'fetchListsForUser',
    options: {
      method: 'POST',
    },
    body: {
      userId,
    },
    transform: (body) => ({
      lists: body,
    }),
    update: {
      lists: (prevLists = [], next) => {
        if (!next) {
          const newLists = produce(prevLists, (draftState) => {
            draftState = prevLists;
            return draftState;
          });
          return newLists;
        }

        const newLists = produce(prevLists, (draftState) => {
          draftState = [...prevLists, ...next];
          return draftState;
        });

        return newLists;
      },
    },
  }),

  userListsRemove: (params) => ({
    url: Api.userListsRemove(),
    queryKey: 'userListsRemove',
    options: {
      method: 'POST',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      listUsers: body,
    }),
    update: {
      listUsers: (prev, next) => next,
    },
  }),
});

export const {
  userListsAdd,
  userListsRemove,
  fetchUsersAssociatedWithList,
  fetchListsAssociatedWithCurrentUser,
} = userlistQueries(Api, produce);
