import React from 'react';
import styled from 'styled-components';
import UserHeaderDetails from './UserHeaderDetails';
import HeaderMenu from '../HeaderMenu';

const GlobalHeaderContainer = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 12px 0 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
`;

const NonMenuContainer = styled.div`
  display: flex;
  width: 75%; // i think the header menu needs pos absolute to do its job
`;

const GlobalHeader = ({ isAuthenticated }) => (
  <GlobalHeaderContainer>
    <NonMenuContainer>
      {isAuthenticated && <UserHeaderDetails />}
    </NonMenuContainer>
    <HeaderMenu />
  </GlobalHeaderContainer>
);

export default GlobalHeader;
