import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { entitiesReducer, queriesReducer, errorsReducer } from 'redux-query/advanced';
import { queryMiddleware } from 'redux-query';
import invariant from 'redux-immutable-state-invariant'; // eslint-disable-line
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducers from '../reducers';

const isProduction = process.env.NODE_ENV === 'production';

const reducers = combineReducers({
  entities: entitiesReducer,
  queries: queriesReducer,
  errors: errorsReducer,
  ...appReducers
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);

const resolveMiddleware = () => {
  const reduxQueryMiddleware = queryMiddleware(state => state.queries, state => state.entities);
  const middleware = [thunk, reduxQueryMiddleware];
  if (!isProduction) {
    const composeEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
    // return composeEnhancer(applyMiddleware(...middleware));
    // TODO: I haven't been able to fix an invariant error on insertItem using immer
    return composeEnhancer(applyMiddleware(...middleware, invariant({ ignore: ['queries'] })));
  }
  return compose(applyMiddleware(...middleware));
};

const middleware = resolveMiddleware();

const envReducers = isProduction ? reducers : persistedReducer;

const store = createStore(envReducers, middleware);
export default store;

export const persistor = persistStore(store);
