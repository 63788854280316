import { omit } from 'lodash/fp';
import produce from 'immer';
import Api from '../../api';

export const itemQueries = (Api, produce) => ({
  fetchItems: () => ({
    url: Api.fetchItems(),
    queryKey: 'fetchItems',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      items: body,
    }),
    update: {
      items: (prev, next) => next,
    },
  }),
  fetchItemCategories: () => ({
    url: Api.fetchItemCategories(),
    queryKey: 'fetchItemCategories',
    options: {
      method: 'GET',
    },
    transform: (body) => {
      console.log({ body });
      return {
        categories: body?.map((c) => c?.category).filter((c) => c?.length > 1),
      };
    },
    update: {
      categories: (prev, next) => next,
    },
  }),

  updateItem: (params) => ({
    url: Api.updateItem({ id: params.id }),
    queryKey: 'updateItem',
    options: {
      method: 'PUT',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      lists: body,
    }),
    update: {
      lists: (prev, next) => {
        /**
         *         const updated = produce(prevLists, draft => {
          if (!newItem) {
            return draft;
          }
          prevLists.forEach((list, listIndex) => {
            if (list.id === params.listId) {
              list.items.forEach((item, itemIndex) => {
                if (item.id === params.itemId) {
                  // eslint-disable-next-line
                  draftState.list[listIndex].items.concat(newItem)
                }
              });
            }
          });
        });
        return updated;
         */
        if (!next) return prev;
        const { id, listId } = params;
        const affectedList = prev.find((l) => l.id === listId);
        const updatedItems = affectedList.items
          .filter((i) => i.id !== id)
          .concat(next);

        const updatedList = Object.assign({}, omit('items')(affectedList), {
          items: updatedItems,
        });

        const newLists = [
          ...prev.filter((p) => p.id !== params.listId),
          updatedList,
        ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        return newLists;
      },
    },
  }),

  insertItem: (params) => ({
    url: Api.insertItem(),
    queryKey: 'insertItem',
    options: {
      method: 'POST',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      lists: body,
    }),
    update: {
      lists: (prevLists, newItem) => {
        const updated = produce(prevLists, (draft) => {
          if (!newItem) {
            draft = prevLists;
          }
          prevLists.forEach((list, i) => {
            if (list.id === params.listId) {
              draft[i].items.push(newItem);
            }
          });
        });
        return updated;
      },
    },
  }),

  bulkInsertItems: (items) => ({
    url: Api.bulkInsertItems(),
    queryKey: 'bulkInsertItems',
    options: {
      method: 'POST',
    },
    body: items,
    transform: (body) => ({
      lists: body,
    }),
    update: {
      lists: (prevLists, returnedList) => {
        const updated = produce(prevLists, (draft) => {
          if (!returnedList) {
            draft = prevLists;
          }
          prevLists.forEach((list, i) => {
            if (list.id === returnedList.id) {
              draft[i].items = returnedList.items;
            }
          });
        });
        return updated;
      },
    },
  }),

  deleteItem: ({ id, listId }) => ({
    url: Api.deleteItem({ id }),
    queryKey: 'deleteItem',
    options: {
      method: 'DELETE',
    },
    body: {
      listId,
    },
    transform: (body) => ({
      lists: body,
    }),
    optimisticUpdate: {
      lists: (prev) => {
        const updated = produce(prev, (draft) => {
          prev.forEach((list, listIndex) => {
            if (list.id === listId) {
              draft[listIndex].items = prev[listIndex].items
                .filter((item) => item.id !== id)
                .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
            }
          });
        });
        return updated;
      },
    },
    rollback: {
      lists: (prev) => {
        const updated = produce(prev, (draft) => {
          prev.forEach((list, listIndex) => {
            if (list.id === listId) {
              draft[listIndex].items = list.items;
            }
          });
        });
        return updated;
      },
    },
    update: {
      lists: (prev, next) => {
        const updated = produce(prev, (draft) => {
          if (!next) {
            draft = prev;
          }
          prev.forEach((list, listIndex) => {
            if (list.id === listId) {
              draft[listIndex].items.filter((item) => item.id !== id);
            }
          });
        });
        return updated;
      },
    },
  }),

  fetchFavouriteItems: ({ userId }) => ({
    url: Api.fetchFavouriteItems({ userId }),
    queryKey: 'fetchFavouriteItems',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      favourites: body,
    }),
    update: {
      favourites: (prev, next) => next,
    },
  }),

  toggleItemCompleted: ({ id, listId }) => ({
    url: Api.itemComplete({ id }),
    queryKey: 'toggleItemCompleted',
    options: {
      method: 'POST',
    },
    body: {
      listId,
    },
    transform: (body) => ({
      lists: body,
    }),
    optimisticUpdate: {
      lists: (prevLists) => {
        const updated = produce(prevLists, (draft) => {
          prevLists.forEach((list, listIndex) => {
            if (list.id === listId) {
              list.items.forEach((item, itemIndex) => {
                if (item.id === id) {
                  // eslint-disable-next-line
                  draft[listIndex].items[itemIndex].completed = !item.completed;
                }
              });
            }
          });
        });
        return updated;
      },
    },
    rollback: {
      lists: (prevLists) => {
        const updated = produce(prevLists, (draft) => {
          prevLists.forEach((list, listIndex) => {
            if (list.id === listId) {
              list.items.forEach((item, itemIndex) => {
                if (item.id === id) {
                  // eslint-disable-next-line
                  draft[listIndex].items[itemIndex].completed = item.completed;
                }
              });
            }
          });
        });
        return updated;
      },
    },
    update: {
      lists: (prevLists, updatedItem) => {
        const updated = produce(prevLists, (draft) => {
          if (!updatedItem) {
            draft = prevLists;
          }
          prevLists.forEach((list, listIndex) => {
            if (list.id === listId) {
              list.items.forEach((item, itemIndex) => {
                if (item.id === id) {
                  // eslint-disable-next-line
                  draft[listIndex].items[itemIndex] = updatedItem;
                }
              });
            }
          });
        });
        return updated;
      },
    },
  }),
});

export const {
  fetchItems,
  updateItem,
  insertItem,
  deleteItem,
  fetchFavouriteItems,
  fetchItemCategories,
  toggleItemCompleted,
  bulkInsertItems,
} = itemQueries(Api, produce);
