export const checkEmail = '/api/auth/checkemail';
export const checkToken = '/api/auth/checktoken';
export const createToken = '/api/auth/createtoken';
export const login = '/api/auth/login';
export const logout = '/api/auth/logout';
export const signup = '/api/auth/signup';
export const checkReferralToken = '/api/auth/checkreferraltoken';
export const forgotPassword = '/api/auth/forgotpassword';
export const changePassword = '/api/auth/changePassword';
export const resetPassword = '/api/auth/resetPassword';
