import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isNil } from 'lodash/fp';
import { connect } from 'react-redux';
import equals from 'fast-deep-equal';
import Spin from 'antd/lib/spin';
import { requestAsync, mutateAsync } from 'redux-query';
import { checkAuth, checkReferralToken } from './redux/queries';
import { formatReferralToken, compose } from './utils';
import {
  selectIsAuthenticated,
  selectEmailHasAccount,
  selectCurrentUserEmail,
  selectValidTokenResponse,
  selectQuerySuccess,
  CHECK_REFERRAL_TOKEN,
  CHECK_EMAIL_HAS_ACCOUNT,
} from './selectors';

class ReferralRoute extends Component {
  cancelled = false;

  componentDidMount() {
    this.checkSearchQueryTokenOnMount();
  }

  componentDidUpdate(prevProps) {
    if (this.cancelled) return null;
    this.handleNewUserLogout(prevProps);
    this.handleRedirectFromReferralRoute(prevProps);
    return null;
  }

  componentWillUnmount() {
    this.cancelled = true;
  }

  // Decode token from referral email url, parse via backend and set inviteUserReponse in store with result,
  checkSearchQueryTokenOnMount = () => {
    const referralSearchQuery = this.props?.location?.search ?? '';
    const {
      dispatchCheckReferralToken = '',
      checkReferralTokenSuccess = '',
    } = this.props;
    if (
      referralSearchQuery &&
      dispatchCheckReferralToken &&
      !checkReferralTokenSuccess
    ) {
      const formattedToken = formatReferralToken(referralSearchQuery);
      dispatchCheckReferralToken(formattedToken);
    }
  };

  receivedValidInviteUserResponse = (prevProps) => {
    const validTokenResponse = this.props?.validTokenResponse ?? false;
    const lastValidTokenResponse = prevProps?.validTokenResponse;
    return !equals(
      validTokenResponse,
      lastValidTokenResponse && !isNil(validTokenResponse)
    );
  };

  // if the logged in user is not the same as the referral token user, remove token and proceed.
  handleNewUserLogout = (prevProps) => {
    if (this.receivedValidInviteUserResponse(prevProps)) {
      const tokenUserEmail = this.props?.validTokenResponse?.email ?? null;
      const { currentUserEmail } = this.props;
      if (tokenUserEmail !== currentUserEmail) {
        localStorage.removeItem('token');
      }
    }
  };

  getEmailHasAccount = (prevProps) => {
    const hasAccountResponse = this.props?.emailHasAccount?.exists ?? false;
    const lastHasAccountResponse = prevProps?.emailHasAccount?.exists ?? false;
    const accountChecked =
      hasAccountResponse !== lastHasAccountResponse &&
      typeof hasAccountResponse === 'boolean'
        ? hasAccountResponse
        : null;

    return accountChecked;
  };

  // user does not have a valid auth token
  // if user has an account associated with the invite email, redirect to /login, otherwise /signup (then to url of resource from invite)
  handleRedirectFromReferralRoute = () => {
    const {
      isAuthenticated,
      history,
      validTokenResponse: { referralRedirect = '/signup' },
    } = this.props;
    const listId = this.props?.validTokenResponse?.invitee?.listId;
    if (!listId) return null;

    const referralURL = `/lists/detail?list=${listId}`;

    if (!isAuthenticated)
      return history.push(referralRedirect, { referralURL });
    return history.push(referralURL);
  };

  render() {
    if (this.cancelled) return null;
    return <Spin className="list-not-found" size="large" />;
  }
}

const mapStateToProps = (state) => ({
  currentUserEmail: selectCurrentUserEmail(state),
  validTokenResponse: selectValidTokenResponse(state),
  isAuthenticated: selectIsAuthenticated(state),
  emailHasAccount: selectEmailHasAccount(state),
  checkEmailHasAccountSuccess: selectQuerySuccess(
    state,
    CHECK_EMAIL_HAS_ACCOUNT
  ),
  checkReferralTokenSuccess: selectQuerySuccess(state, CHECK_REFERRAL_TOKEN),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCheckAuth: (token) => dispatch(requestAsync(checkAuth(token))),
  dispatchCheckReferralToken: (token) =>
    dispatch(mutateAsync(checkReferralToken(token))),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(ReferralRoute);
