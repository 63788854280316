import Api from '../../api';

export const uploadImage = (image) => ({
  url: Api.uploadImage(),
  queryKey: 'uploadImage',
  options: {
    method: 'POST',
    headers: {
      Accept: 'application/json',

    },
  },
  body: {
    image,
  },
  transform: (body) => ({
    uploadResponse: body,
  }),
  update: {
    uploadResponse: (prev, next) => next,
  },
});

export const uploadImageImgur = (image) => ({
  url: process.env.REACT_APP_UPLOAD_IMAGE_TARGET,
  queryKey: 'uploadImage',
  options: {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_IMGUR_ACCESS_TOKEN}`, // this is valid for around a month -  no programatic way of getting it :(
    },
  },
  body: {
    image,
  },
  transform: (body) => ({
    uploadResponse: body,
  }),
  update: {
    uploadResponse: (prev, next) => next,
  },
});

export const getImgurRefreshToken = () => {
  const url = `${process.env.REACT_APP_IMGUR_ACCESS_TOKEN_URL}?state=APPLICATION&response_type=token&client_id=${process.env.REACT_APP_IMGUR_CLIENT_ID}&client_secret=${process.env.REACT_APP_IMGUR_CLIENT_SECRET}`;
  return {
    url,
    queryKey: 'getImgurRefreshToken',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      imgurRefreshRespone: body,
    }),
    update: {
      imgurRefreshRespone: (prev, next) => next,
    },
  };
};
