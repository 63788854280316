import produce from 'immer';
import Api from '../../api';

export const userQueries = (Api, produce) => ({
  _produce: produce,
  updateUser: (values) => ({
    url: Api.updateUser({ userId: values.id }),
    queryKey: 'updateUser',
    options: {
      method: 'POST',
    },
    body: {
      ...values,
    },
    transform: (body) => ({
      authenticated: body,
    }),
    update: {
      authenticated: (prev, next) => next,
    },
  }),

  searchUsers: (searchTerm) => ({
    url: Api.searchUsers(),
    queryKey: 'searchUsers',
    options: {
      method: 'POST',
    },
    body: {
      searchTerm,
    },
    transform: (body) => ({
      searchResultUsers: body,
    }),
    update: {
      searchResultUsers: (prev, next) => next,
    },
  }),

  fetchCurrentUser: (id) => ({
    url: Api.fetchUser({ id }),
    queryKey: 'fetchUser',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      currentUser: body,
    }),
    update: {
      currentUser: (prev, next) => {
        return next;
        // // eslint-disable-next-line no-unused-vars
        // const newCurrentUser = produce(prev, (draftState) => {
        //   draftState = next;
        // });

        // return newCurrentUser;
      },
    },
  }),
});

export const { updateUser, searchUsers, fetchCurrentUser } = userQueries(Api, produce);
