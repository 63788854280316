import { urlB64ToUint8Array } from './utils/utils';

const isSubscribed = subscription => !(subscription === null);

const getPushSubscription = async swRegistration => {
  let sub = await swRegistration.pushManager.getSubscription();

  if (!isSubscribed(sub)) {
    const applicationServerKey = urlB64ToUint8Array(process.env.REACT_APP_PUSH_APPLICATION_SERVER_KEY);
    try {
      sub = await swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      });
    } catch (err) {
      // eslint-disable-next-line
      console.error('Failed to subscribe user', { err });
    }
  }
  return sub;
};

const registerValidSW = async (swUrl, config) => {
  try {
    const existing = await navigator.serviceWorker.getRegistration();
    if (existing) {
      // eslint-disable-next-line
      console.log('SW registration exists', existing);
      await existing.unregister();
      // eslint-disable-next-line
      console.log('removed SW');
    }

    const swRegistration = await navigator.serviceWorker.register(swUrl);
    if (swRegistration) {
      // eslint-disable-next-line
      console.log('SW has registered!', { swRegistration });
    }
    const subscription = await getPushSubscription(swRegistration);
    // eslint-disable-next-line
    console.log('This is your subscription. There are many like it, but this one is yours.', { subscription });
    // eslint-disable-next-line
    console.log('Set subscription in local host here. Post it to the DB on user signup or in settings');
    localStorage.setItem('subscription', JSON.stringify(subscription));
    //
    swRegistration.onupdatefound = () => {
      const installingWorker = swRegistration.installing;
      if (installingWorker == null) {
        return;
      }
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          if (navigator.serviceWorker.controller) {
            if (config && config.onUpdate) {
              config.onUpdate(swRegistration);
            }
          }
          if (config && config.onSuccess) {
            config.onSuccess(swRegistration);
          }
        }
      };
    };
  } catch (err) {
    // eslint-disable-next-line
    console.error('Error during service worker registration:', { err });
  }
};

export const register = config => {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', async () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`;
      await Promise.all([navigator.serviceWorker.ready, registerValidSW(swUrl, config)]);
    });
  }
};

export const unregister = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
};
