import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Item from './Item';
import style from './ItemList.module.scss';

const ItemList = ({ items, loading, handleClick, readOnly = false }) => (
  <div className={classnames(style.itemList, { [style.readOnly]: readOnly })}>
    {items.map((item) => (
      <Item
        key={item.id}
        item={item}
        handleClick={handleClick}
        loading={loading}
        readOnly={readOnly}
      />
    ))}
  </div>
);

ItemList.propTypes = {
  handleClick: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

ItemList.defaultProps = {
  items: [],
  loading: false,
  readOnly: false,
};

export default ItemList;
