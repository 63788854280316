import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import isTrue from './utils';

const selectEntities = (state) => get('entities')(state);

const selectGlobal = (state) => get('global')(state);

/**
 * Signup responses
 */
export const selectValidTokenResponse = createSelector(
  selectEntities,
  (entities) => get('validTokenResponse')(entities) || {}
);

export const selectSignupResponse = createSelector(selectEntities, (entities) =>
  get('signupResponse')(entities)
);

export const selectInviteUserResponse = createSelector(
  selectEntities,
  (entities) => get('inviteUserResponse')(entities)
);

export const selectEmailHasAccount = createSelector(
  selectEntities,
  (entities) => get('emailHasAccount')(entities)
);

/**
 *  CURRENT USER
 */
const selectCurrentUser = createSelector(selectEntities, (entities) =>
  get('currentUser')(entities)
);

export const selectCurrentUserEmail = createSelector(
  selectCurrentUser,
  (user) => get('email')(user)
);

export const selectCurrentUserAvatar = createSelector(
  selectCurrentUser,
  (user) => get('avatar')(user)
);

export const selectCurrentUserFirstName = createSelector(
  selectCurrentUser,
  (user) => get('firstName')(user)
);

export const selectCurrentUserLastName = createSelector(
  selectCurrentUser,
  (user) => get('lastName')(user)
);

export const selectCurrentUserId = createSelector(selectCurrentUser, (user) =>
  get('id')(user)
);

export const selectCurrentUserEnableNotifications = createSelector(
  selectCurrentUser,
  (user) => get('enableNotifications')(user)
);

/**
 * AUTH
 */
const selectAuthenticated = createSelector(selectEntities, (entities) =>
  isTrue(get('authenticated')(entities))
);

export const selectIsAuthenticated = createSelector(
  selectAuthenticated,
  selectCurrentUserId,
  (auth, userId) => auth && !!userId
);

/**
 * LISTS
 */
export const selectLists = createSelector(selectEntities, (entities) =>
  get('lists')(entities)
);

export const selectList = createSelector(
  selectEntities,
  (entities) => get('list')(entities) || {}
);

export const selectItemCategories = createSelector(
  selectEntities,
  (entities) => entities?.categories ?? []
);

export const selectActiveListId = createSelector(selectGlobal, (global) =>
  get('activeListId')(global)
);

export const selectActiveList = createSelector(
  selectLists,
  selectActiveListId,
  (lists = [], activeListId) => lists.find((list) => list.id === activeListId)
);

/**
 * LIST ITEMS
 */
export const selectActiveListItems = createSelector(
  selectActiveList,
  (list) => {
    const items = get('items')(list) || [];
    return (
      items
        .slice() // handles an Invariant mutation error
        // .filter(a => a.visible)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // finding where items are sorted is kind of hard :/
        .sort((a, b) => a.priority - b.priority)
    );
  }
);

/**
 * LIST USERS
 */
export const selectListUsers = createSelector(
  selectEntities,
  (entities) => get('listUsers')(entities) || []
);

export const selectOtherListUsers = createSelector(
  selectListUsers,
  selectCurrentUserId,
  (listUsers, currentUserId) =>
    listUsers.filter((user) => user.id !== currentUserId)
);

/**
 * FRIENDS
 */
export const selectFriends = createSelector(selectEntities, (entities) =>
  get('friends')(entities)
);

/**
 * FAVOURITES
 */
export const selectFavourites = createSelector(selectEntities, (entities) =>
  get('favourites')(entities)
);

/**
 * RECIPES
 */
export const selectRecipes = createSelector(selectEntities, (entities) =>
  get('recipes')(entities)
);

export const selectRecipe = createSelector(
  selectEntities,
  (entities) => get('recipe')(entities) || {}
);
