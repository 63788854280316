import { get } from 'lodash/fp';
import Api from '../../api';

export const login = ({ email, password }) => ({
  url: Api.login(),
  queryKey: 'login',
  options: {
    method: 'POST',
  },
  body: {
    email,
    password,
  },
  transform: (body) => ({
    authenticated: body,
    currentUser: body,
  }),
  optimisticUpdate: {
    authenticated: () => null,
  },
  update: {
    authenticated: (prev, next) => get('authenticated')(next),
    currentUser: (prev, next) => get('user')(next),
  },
});

export const signup = (values) => ({
  url: Api.signup(),
  queryKey: 'signup',
  options: {
    method: 'POST',
  },
  body: {
    ...values,
  },
  transform: (body) => ({
    currentUser: body,
    authenticated: body,
    userExists: body,
  }),
  optimisticUpdate: {
    userExists: () => false,
  },
  update: {
    currentUser: (prev, next) => get('signupResponse.user')(next),
    authenticated: (prev, next) => get('signupResponse.authenticated')(next),
    token: (prev, next) => get('signupResponse.token')(next),
    userExists: (prev, next) => get('signupResponse.userExists')(next),
  },
});

export const checkAuth = (token) => ({
  url: Api.checkToken(),
  queryKey: 'checkAuth',
  meta: {
    request: 'checkAuth',
  },
  options: {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept' 
    }
  },
  body: {
    token,
  },
  transform: (body) => ({
    authenticated: body,
    currentUser: body,
  }),
  update: {
    authenticated: (prev, next) => get('authenticated')(next),
    currentUser: (prev, next) => get('user')(next),
  },
});

export const createToken = ({ email, userId }) => ({
  url: Api.createToken(),
  queryKey: 'createToken',
  options: {
    method: 'GET',
  },
  body: {
    email,
    userId,
  },
  transform: (body) => ({
    token: body,
  }),
  update: {
    token: (prev, next) => next,
  },
});

export const checkEmailHasAccount = (email) => ({
  url: Api.checkEmail(),
  queryKey: 'checkEmail',
  options: {
    method: 'POST',
  },
  body: {
    email,
  },
  transform: (body) => ({
    emailHasAccount: body,
  }),
  update: {
    emailHasAccount: (prev, next) => next,
  },
});

export const logout = (email) => ({
  url: Api.logout(),
  queryKey: 'authLogout',
  options: {
    method: 'POST',
  },
  body: {
    email,
  },
  transform: (body) => ({
    token: body,
    authenticated: body,
    currentUser: body,
  }),
  update: {
    token: () => null,
    authenticated: () => false,
    currentUser: () => null,
  },
});

export const checkReferralToken = (token) => ({
  url: Api.checkReferralToken(),
  queryKey: 'checkReferralToken',
  options: {
    method: 'POST',
  },
  body: {
    token,
  },
  transform: (body) => ({
    validTokenResponse: body,
  }),
  update: {
    validTokenResponse: (prev, next) => next,
  },
});

export const forgotPassword = (email) => ({
  url: Api.forgotPassword(),
  queryKey: 'forgotPassword',
  options: {
    method: 'POST',
  },
  body: {
    email,
  },
  transform: (body) => ({
    token: body,
    authenticated: body,
    currentUser: body,
    forgotPasswordEmailSuccess: body,
  }),
  update: {
    token: () => null,
    authenticated: () => false,
    currentUser: () => null,
    forgotPasswordEmailSuccess: (prev, next) => next.resetPasswordEmailSuccess,
  },
  optimisticUpdate: {
    forgotPasswordEmailSuccess: () => null,
  },
});

export const changePassword = (values) => ({
  url: Api.changePassword(),
  queryKey: 'changePassword',
  options: {
    method: 'POST',
  },
  body: {
    ...values,
  },
  transform: (body) => ({
    changePasswordSuccess: body,
  }),
  update: {
    changePasswordSuccess: (prev, next) => next.changePasswordSuccess,
  },
});

export const resetPassword = (values) => ({
  url: Api.resetPassword(),
  queryKey: 'resetPassword',
  options: {
    method: 'POST',
  },
  body: {
    ...values,
  },
  transform: (body) => ({
    resetPasswordSuccess: body,
    forgotPasswordEmailSuccess: () => undefined,
  }),
  optimisticUpdate: {
    resetPasswordSuccess: () => null,
  },
  update: {
    resetPasswordSuccess: (prev, next) => next.resetPasswordSuccess,
    forgotPasswordEmailSuccess: () => undefined,
  },
});
