import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './GridContent.scss';

class GridContent extends PureComponent {
  render() {
    const { setting, children } = this.props;
    const contentWidth = setting?.contentWidth;
    let className = 'main';
    if (contentWidth === 'Fixed') {
      className = 'main wide';
    }
    return <div className={className}>{children}</div>;
  }
}

export default connect(({ setting }) => ({
  setting,
}))(GridContent);
