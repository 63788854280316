import Api from '../../api';

export const updateList = (params) => ({
  url: Api.updateList({ id: params.id }),
  queryKey: 'updateList',
  options: {
    method: 'PUT',
  },
  body: {
    ...params,
  },
  transform: (body) => ({
    lists: body,
  }),
  update: {
    lists: (prev, next) => {
      if (!next) return prev;
      return [next, ...prev.filter((p) => p.id !== params.id)];
    },
  },
});

export const fetchList = (id) => {
  return {
    url: Api.fetchList({ id }),
    queryKey: 'fetchList',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      list: body,
    }),
    update: {
      list: (prev, next) => next,
    },
  };
};

export const insertList = (params) => ({
  url: Api.insertList(),
  queryKey: 'insertList',
  options: {
    method: 'POST',
  },
  body: {
    ...params,
  },
  transform: (body) => ({
    lists: body,
  }),
  update: {
    lists: (prev, next) => {
      if (!next) return prev;
      if (!prev) return [next];
      return [next, ...prev];
    },
  },
});

export const insertListWithItems = (params) => ({
  url: Api.insertListWithItems(),
  queryKey: 'insertListWithItems',
  options: {
    method: 'POST',
  },
  body: {
    ...params,
  },
  transform: (body) => ({
    lists: body,
  }),
  update: {
    lists: (prev, next) => {
      if (!next) return prev;
      if (!prev) return [next];
      return [next, ...prev];
    },
  },
});

export const deleteList = ({ id }) => ({
  url: Api.deleteList({ id }),
  queryKey: 'deleteList',
  options: {
    method: 'DELETE',
  },
  transform: (body) => ({
    lists: body,
  }),
  update: {
    lists: (prev) => {
      if (!prev) return [];
      const newLists = id ? prev.filter((p) => p.id !== id) : prev;
      return newLists;
    },
  },
});
