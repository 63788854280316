import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { withRouter } from 'react-router-dom';
import BurgerMenu from 'react-burger-menu';
import styled from 'styled-components';
import { compose } from '../../utils/utils';
import { logout } from '../../redux/queries';
import { selectCurrentUserEmail, selectIsAuthenticated } from '../../selectors';
import './HeaderMenu.scss';

const InactiveItems = styled.span`
  color: #989898 !important;
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

class HeaderMenu extends Component {
  state = {
    menuIsOpen: false,
  };

  getItems() {
    const { isAuthenticated } = this.props;
    const authItems = [
      <div key="lists" onClick={() => this.handleMenuClick({ key: 'lists' })}>
        <span>View your Lists</span>
      </div>,
      <div
        key="recipes"
        onClick={() => this.handleMenuClick({ key: 'recipes' })}
      >
        <span>Manage your Recipes</span>
      </div>,
      <div key="home" onClick={() => this.handleMenuClick({ key: 'home' })}>
        <span>Home</span>
      </div>,
      <div
        key="account"
        onClick={() => this.handleMenuClick({ key: 'account' })}
      >
        <span>Account Details</span>
      </div>,
      <div
        key="settings"
        onClick={() => this.handleMenuClick({ key: 'settings' })}
      >
        <span>Settings</span>
      </div>,
      <div key="about" onClick={() => this.handleMenuClick({ key: 'about' })}>
        <span>About</span>
      </div>,
      <div key="notifications">
        <InactiveItems>Notifications</InactiveItems>
      </div>,
      <div key="messages">
        <InactiveItems>Messages</InactiveItems>
      </div>,
      <div key="comments">
        <InactiveItems>Comments</InactiveItems>
      </div>,
      <div key="events">
        <InactiveItems>Events</InactiveItems>
      </div>,
      <div key="space">
        <span></span>
      </div>,
      <div key="logout" onClick={() => this.handleMenuClick({ key: 'logout' })}>
        <span>Logout</span>
      </div>,
    ];

    const nonAuthItems = [
      <div key="60" onClick={() => this.handleMenuClick({ key: 'login' })}>
        <span>Login</span>
      </div>,
      <div key="61" onClick={() => this.handleMenuClick({ key: 'signup' })}>
        <span>Signup</span>
      </div>,
      <div key="62" onClick={() => this.handleMenuClick({ key: 'home' })}>
        <span>Home</span>
      </div>,
    ];

    return isAuthenticated ? authItems : nonAuthItems;
  }

  handleCloseMenu = () => this.setState({ menuIsOpen: false });

  handleStateChange = (state) => this.setState({ menuIsOpen: state.isOpen });

  handleMenuClick = ({ key }) => {
    const { history, dispatchLogout, currentUserEmail } = this.props;
    this.handleCloseMenu();
    if (key === 'account') {
      history.push('/account');
      return;
    }
    if (key === 'recipes') {
      history.push('/recipes');
      return;
    }
    if (key === 'settings') {
      history.push('/settings');
      return;
    }
    if (key === 'about') {
      history.push('/about');
      return;
    }
    if (key === 'lists') {
      history.push('/lists');
      return;
    }
    if (key === 'login') {
      history.push('/login');
      return;
    }
    if (key === 'signup') {
      history.push('/signup');
      return;
    }
    if (key === 'home') {
      history.push('/');
      return;
    }
    if (key === 'logout') {
      dispatchLogout(currentUserEmail);
      localStorage.removeItem('token');
    }
  };

  render() {
    const Menu = BurgerMenu.stack;
    const { menuIsOpen } = this.state;

    return (
      <MenuContainer>
        {menuIsOpen && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              zindex: 2,
            }}
            onClick={this.handleCloseMenu}
          />
        )}
        <Menu
          id="stack"
          isOpen={menuIsOpen}
          right
          onStateChange={(state) => this.handleStateChange(state)}
        >
          {this.getItems()}
        </Menu>
      </MenuContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUserEmail: selectCurrentUserEmail(state),
  isAuthenticated: selectIsAuthenticated(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchLogout: (email) => dispatch(mutateAsync(logout(email))),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(HeaderMenu);
