import produce from 'immer';
import Api from '../../api';

export const connectQueries = (Api, produce) => ({
  _produce: produce,
  inviteUsers: (params) => ({
    url: Api.inviteUsers(),
    queryKey: 'inviteUsers',
    options: {
      method: 'POST',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      inviteUsersResponse: body,
    }),
    update: {
      inviteUsersResponse: (prev, next) => next,
    },
  }),

  fetchFriends: (params) => ({
    url: Api.fetchFriends(),
    queryKey: 'fetchFriends',
    options: {
      method: 'POST',
    },
    body: {
      ...params,
    },
    transform: (body) => ({
      friends: body,
    }),
    update: {
      friends: (prev, next) => next,
    },
  }),

  createFriendship: ({ leftUserId, rightUserId }) => ({
    url: Api.createFriendship(),
    queryKey: 'createFriendship',
    options: {
      method: 'POST',
    },
    body: {
      leftUserId,
      rightUserId,
    },
    transform: (body) => ({
      friends: body,
    }),
    update: {
      friends: (prev, next) => next,
    },
  }),
});

export const { inviteUsers, fetchFriends, createFriendship } = connectQueries(Api, produce);
