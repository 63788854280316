import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { EditOutlined, DeleteOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { compose } from '../../../utils/utils';
import { toggleItemCompleted } from '../../../redux/queries';
import ItemContent from './ItemContent';
import style from './Item.module.scss';

class ListItem extends Component {
  handleEdit = () => {
    const { handleClick, item } = this.props;
    handleClick('edit', item);
  };

  handleDelete = () => {
    const { handleClick, item } = this.props;
    handleClick('delete', item);
  };

  handleToggleComplete = () => {
    const { dispatchToggleItemCompleted, item } = this.props;
    dispatchToggleItemCompleted(item);
  };

  render() {
    const { item, readOnly } = this.props;
    return (
      <div className={style.item}>
        <div>
          <ItemContent item={item} />
        </div>
        <div className={classnames(style.actionsContainer, { [style.hidden]: readOnly })}>
          <div onClick={this.handleEdit}>
            <EditOutlined />
          </div>
          <div onClick={this.handleDelete}>
            <DeleteOutlined />
          </div>
          <div
            onClick={this.handleToggleComplete}
            className={classnames(item.completed ? style.checked : style.unchecked)}
          >
            <CheckCircleOutlined />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchToggleItemCompleted: (itemId, listId) => dispatch(mutateAsync(toggleItemCompleted(itemId, listId)))
});

const enhance = compose(
  connect(
    undefined,
    mapDispatchToProps
  )
);

ListItem.propTypes = {
  dispatchToggleItemCompleted: PropTypes.func.isRequired,
  handleClick: PropTypes.func,
  item: PropTypes.shape({
    title: PropTypes.string,
    loading: PropTypes.bool
  }).isRequired
};

export default enhance(ListItem);
