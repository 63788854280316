import React, { Fragment } from 'react';
import Avatar from 'antd/lib/avatar';
import { Tag } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AvatarDefault from '../../../assets/avartarDefault.jpeg';
import OwnerModifiedDate from './OwnerModifiedDate';
import style from './ItemContent.module.scss';

const TitleCatContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 0.5rem;
`;

const formatCat = (cat) => cat.replaceAll('-', ' ');

const ItemContent = (props) => {
  const {
    item: { text, title, updatedAt, owner = {}, category },
  } = props;
  const getDisplayDate = OwnerModifiedDate(owner, updatedAt, 'updated');

  return (
    <Fragment>
      <div className={style.contentHeader}>
        <div className={style.avatar}>
          <Avatar src={owner.avatar || AvatarDefault} />
        </div>
        <TitleCatContainer>
          <div className={style.title}>{title}</div>
          {category && (
            <Tag
              color="#108ee9"
              style={{
                width: 'fit-content',
                borderRadius: '12px',
                textTransform: 'capitalize',
              }}
            >
              {formatCat(category)}
            </Tag>
          )}
        </TitleCatContainer>
      </div>
      <div className={style.text}>
        {text}
        {getDisplayDate}
      </div>
    </Fragment> // text is optional extra detail - title is returned in item
  );
};

ItemContent.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
    title: PropTypes.string,
    updatedAt: PropTypes.string.isRequired,
    owner: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
};

export default ItemContent;
