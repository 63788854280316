import produce from 'immer';
import Api from '../../api';

export const notificationQueries = (Api, produce) => ({
  _produce: produce,
  postSubscription: (payload) => ({
    url: Api.postSubscription(),
    options: {
      method: 'POST',
    },
    queryKey: 'postSubscription',
    meta: {
      request: 'postSubscription',
    },
    body: {
      ...payload,
    },
    transform: (body) => ({
      currentUser: body,
    }),
    update: {
      currentUser: (prev, next) => ({
        ...prev,
        subscription: next.subscription || prev.subscription,
        ...next.user,
      }),
    },
  }),

  pushNotification: (payload) => ({
    url: Api.pushNotification(),
    options: {
      method: 'POST',
    },
    queryKey: 'pushNotification',
    meta: {
      request: 'pushNotification',
    },
    body: {
      ...payload,
    },
    transform: (body) => ({
      loading: body,
      notificationRequest: body,
    }),
    update: {
      loading: (next) => next,
      notificationRequest: (next) => next,
    },
  }),

  emailNotification: (payload) => ({
    url: Api.emailNotification(),
    options: {
      method: 'POST',
    },
    queryKey: 'listChangeNotification',
    meta: {
      request: 'listChangeNotification',
    },
    body: {
      ...payload,
    },
    transform: (body) => ({
      loading: body,
    }),
    update: {
      loading: (next) => next,
    },
  }),
});

export const { postSubscription, pushNotification, emailNotification } = notificationQueries(Api, produce);
