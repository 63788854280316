import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AuthContainer from './AuthContainer';
import 'antd/dist/antd.css';
import store, { persistor } from './redux/store/configureStore';
import ErrorBoundary from './components/ErrorBoundary';

const App = () => (
  <Provider store={store}>
    <ErrorBoundary>
      {process.env.NODE_ENV === 'production' ? (
        <BrowserRouter>
          <AuthContainer />
        </BrowserRouter>
      ) : (
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <AuthContainer />
          </BrowserRouter>
        </PersistGate>
      )}
    </ErrorBoundary>
  </Provider>
);

export default App;
