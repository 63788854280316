import { createSelector } from 'reselect';
import { differenceInSeconds } from 'date-fns';
import { get } from 'lodash/fp';
import isTrue from './utils';

const selectQueries = (state) => get('queries')(state);
const selectQuery = (state, queryName) => get(`queries.${queryName}`)(state);

export const selectQueryStarted = createSelector(
  selectQuery,
  (req) => get('isPending')(req) && !get('isFinished')(req)
);

export const selectQueryFinished = createSelector(
  selectQuery,
  (req) => !isTrue(get('isPending')(req)) && isTrue(get('isFinished')(req))
);

export const isStale = (lastUpdatedUnix, now, threshold = 5) => {
  if (!lastUpdatedUnix) {
    return true;
  }
  return differenceInSeconds(now, new Date(lastUpdatedUnix)) > threshold;
};

// 20Feb: want to be able to invalidate query status', setting it to false here with an is stale check
// has side effects throughout the app as I guess there are somewhere, !querysuccess && show nothings.
// seek and destroy them

export const selectQuerySuccess = createSelector(
  selectQuery,
  (req) =>
    !isTrue(get('isPending')(req)) &&
    isTrue(get('isFinished')(req)) &&
    // isNotStale(get('lastUpdated')(req)) &&
    get('status')(req).toString()[0] === '2'
);

export const selectIsQuerySuccessStale = (state, queryName) => {
  const req = get(`queries.${queryName}`)(state);
  return {
    result:
      !isTrue(get('isPending')(req)) &&
      isTrue(get('isFinished')(req)) &&
      get('status')(req).toString()[0] === '2',
    lastUpdated: get('lastUpdated')(req),
  };
};

export const selectLoading = createSelector(selectQueries, (queries) =>
  Object.entries(queries).some((query) => query[1].isPending)
);
