import React from 'react';
import notification from 'antd/lib/notification';
import { get, first } from 'lodash/fp';

const filterErrors = errors => errors;

const ErrorNotification = errors => {
  const error = filterErrors(errors);
  if (!error) return null;
  const errorObject = first(Object.values(errors));
  const description = get('responseBody.message')(errorObject) || 'Probably nothing to worry about';

  return notification.open({
    message: 'Do you smell that?',
    description,
    icon: (
      <span role="img" aria-label="broke" style={{ fontSize: '36px' }}>
        💩
      </span>
    )
  });
};

export default ErrorNotification;
