import { assignAll } from 'lodash/fp';
import { transform } from 'lodash';
import createRequestUri from '../utils/network';
import * as Auth from './auth';
import * as Connect from './connect';
import * as Item from './item';
import * as List from './list';
import * as User from './user';
import * as UserLists from './userlist';
import * as Notifications from './notifications';
import * as Image from './image';
import * as Recipe from './recipe';

const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';

const generateUri =
  (path) =>
  (parameters = null, query = null) => {
    const template = `${SERVER_URL}${path}`;
    return createRequestUri({ template, parameters, query });
  };

const api = assignAll(
  [
    Auth,
    Connect,
    Item,
    List,
    User,
    UserLists,
    Notifications,
    Image,
    Recipe,
  ].map(
    (area) =>
      transform(
        area,
        (acc, template, key) => (acc[key] = generateUri(template))
      ) // eslint-disable-line no-return-assign
  )
);

export default api;
