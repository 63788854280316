import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CoffeeOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import {
  selectCurrentUserAvatar,
  selectCurrentUserFirstName,
} from '../../selectors';

const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Avatar = styled.img`
  border-radius: 50%;
  background-color: lightgrey;
  margin: 8px;
  height: 48px;
  width: 48px;
`;

// const UserName = styled.div`
//   font-family: 'Berkshire Swash', cursive;
//   font-size: 2em;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   overflow: hidden;
// `;

const NavIconsContainer = styled.div`
  margin: 0 16px;
  display: flex;
  flex: 1 0 auto;
  svg {
    height: 36px;
    width: 36px;
    margin: 0 6px;
    color: #007bff;
  }
`;

const UserHeaderDetails = ({
  // firstName,
  avatar,
}) => {
  const history = useHistory();
  return (
    <UserWrapper>
      <NavIconsContainer>
        <ShoppingCartOutlined onClick={() => history.push('/lists')} />
        <CoffeeOutlined onClick={() => history.push('/recipes')} />
      </NavIconsContainer>
      {avatar && <Avatar src={avatar} />}
      {/* <UserName>{firstName || ''}</UserName> */}
    </UserWrapper>
  );
};

UserHeaderDetails.defaultProps = {
  firstName: '',
  avatar: '',
};

const mapStateToProps = (state) => ({
  firstName: selectCurrentUserFirstName(state),
  avatar: selectCurrentUserAvatar(state),
});

export default connect(mapStateToProps)(UserHeaderDetails);
