import React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import style from './OwnerModified.module.scss';

const DisplayDate = (owner = {}, date, optionalPrefixText) => {
  const { firstName, lastName } = owner;
  const firstNameDisplay = firstName ? ` by ${firstName}` : '';

  const lastInitial = !lastName ? '' : `, ${lastName[0]}`;
  return (
    <div className={style.text}>
      {optionalPrefixText} {`${distanceInWordsToNow(new Date(date))} ago ${firstNameDisplay}${lastInitial}`}
    </div>
  );
};

DisplayDate.defaultProps = {
  date: new Date(),
  optionalPrefixText: ''
};

export default DisplayDate;
