export const INSERT_LIST = 'insertList';
export const INSERT_RECIPE = 'insertRecipe';
export const UPDATE_RECIPE = 'updateRecipe';
export const CHECK_TOKEN = 'checkToken';
export const PUSH_NOTIFICATION = 'pushNotification';
export const LIST_CHANGE_NOTIFICATION = 'listChangeNotification';
export const CHECK_AUTH = 'checkAuth';
export const CHECK_REFERRAL_TOKEN = 'checkReferralToken';
export const CHECK_EMAIL_HAS_ACCOUNT = 'checkEmailHasAccount';
export const USER_LISTS_ADD = 'userListsAdd';
export const CREATE_FRIENDSHIP = 'createFriendship';
export const AUTH_LOGOUT = 'authLogout';
export const LOGIN = 'login';
export const FORGOT_PASSWORD = 'forgotPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const RESET_PASSWORD = 'resetPassword';
export const BULK_INSERT_ITEMS = 'bulkInsertItems';
export const INSERT_LIST_WITH_ITEMS = 'insertListWithItems';
