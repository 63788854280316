import types from '../types';

const initialState = {
  collapsed: null,
  notices: [],
  activeListId: null
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LAYOUT_COLLAPSED:
      return {
        ...state,
        collapsed: action.payload
      };

    case types.CHANGE_SETTING:
      return {
        ...state
      };

    case types.SET_ACTIVE_LIST:
      return {
        ...state,
        activeListId: action.listId
      };

    default:
      return state;
  }
};

export default globalReducer;
