import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from '../PageHeader';
import GridContent from './GridContent';
import MenuContext from '../../layouts/MenuContext';
import './index.scss';

const PageHeaderWrapper = ({
  children,
  contentWidth,
  wrapperClassName,
  top,
  ...restProps
}) => (
  <div className={wrapperClassName}>
    {top}
    <MenuContext.Consumer>
      {(value) => (
        <PageHeader
          wide={contentWidth === 'Fixed'}
          {...value}
          key="pageheader"
          {...restProps}
          linkElement={Link}
          itemRender={(item) => {
            if (item.locale) {
              return item.name;
            }
            return item.name;
          }}
        />
      )}
    </MenuContext.Consumer>
    {children ? (
      <div className="content">
        <GridContent>{children}</GridContent>
      </div>
    ) : null}
  </div>
);

export default connect(({ setting }) => ({
  contentWidth: setting?.contentWidth,
}))(PageHeaderWrapper);
