import React, { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';
import { isEqual, get } from 'lodash/fp';
import { requestAsync } from 'redux-query';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import logo from '../assets/livelist.png';
import { compose } from '../utils/utils';
import Header from './Header';
import { fetchListsAssociatedWithCurrentUser } from '../redux/queries';
import { selectActiveListId, selectCurrentUserId } from '../selectors';
import ErrorNotification from './ErrorNotification';
// import BreadcrumbNav from '../pages/List/BreadcrumbNav';

const LayoutWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 4px;
  overflow-y: hidden;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const HeaderSpacer = styled.div`
  height: 64px;
`;

const LoadingFallback = () => (
  <SpinnerWrapper>
    <Spin size="large" />
  </SpinnerWrapper>
);

const BasicLayout = ({
  errors,
  children,
  dispatchFetchListsForUser,
  userId,
}) => {
  const [stateErrors, setErrors] = React.useState();

  useEffect(() => {
    if (!isEqual(stateErrors)(errors)) {
      setErrors(errors);
    }
  }, [errors]);

  useEffect(() => {
    if (userId) {
      dispatchFetchListsForUser({ userId });
    }
  }, [userId]);

  if (Object.entries(errors).length > 1) {
    return <ErrorNotification errors={errors} />;
  }

  return (
    <React.Fragment>
      <Header logo={logo} />
      <HeaderSpacer />
      {/* <BreadcrumbNav /> */}
      <LayoutWrapper>
        <Suspense fallback={<LoadingFallback />}>{children}</Suspense>
      </LayoutWrapper>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  errors: get('errors')(state),
  activeListId: selectActiveListId(state),
  userId: selectCurrentUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchListsForUser: ({ userId }) =>
    dispatch(requestAsync(fetchListsAssociatedWithCurrentUser({ userId }))),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(BasicLayout);
