const types = {
  // LIST
  FETCH_LIST: 'fetch_list',
  FETCH_LISTS: 'fetch_lists',
  APPEND_LIST: 'append_list',
  FILTER_LIST: 'filter_list',
  UPDATE_LIST: 'update_list',
  SUBMIT_LIST: 'submit_list',
  DELETE_LIST: 'delete_list',
  SET_ACTIVE_LIST: 'set_active_list',
  // ITEM
  FETCH_ITEM: 'fetch_item',
  FETCH_ITEMS: 'fetch_items',
  APPEND_ITEM: 'append_item',
  FILTER_ITEM: 'filter_item',
  UPDATE_ITEM: 'update_item',
  SUBMIT_ITEM: 'submit_item',
  DELETE_ITEM: 'delete_item',
  // SETTINGS
  GET_SETTING: 'get_setting',
  CHANGE_SETTING: 'change_setting',
  // GLOBAL
  CHANGE_LAYOUT_COLLAPSED: 'change_layout_collapsed',
  SAVE_NOTICES: 'save_notices',
  SAVE_CLEARED_NOTICES: 'save_cleared_notices',
  // USERS
  FETCH_USERS: 'fetch_users',
  FETCH_CURRENT_USER: 'fetch_current_user',
  SAVE_CURRENT_USER: 'save_current_user',
  CHANGE_NOTIFY_COUNT: 'change_notify_count'
};

export default types;
