import Api from '../../api';

export const updateRecipe = (params) => ({
  url: Api.updateRecipe({ id: params.id }),
  queryKey: 'updateRecipe',
  options: {
    method: 'PUT',
  },
  body: {
    ...params,
  },
  transform: (body) => ({
    recipes: body,
  }),
  update: {
    recipes: (prev, next) => {
      if (!next) return prev;
      return [next, ...prev.filter((p) => p.id !== params.id)];
    },
  },
});

export const fetchRecipe = (id) => {
  return {
    url: Api.fetchRecipe({ id }),
    queryKey: 'fetchRecipe',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      recipe: body,
    }),
    update: {
      recipe: (prev, next) => next,
    },
  };
};

export const fetchUserRecipes = (userId) => {
  return {
    url: Api.fetchUserRecipes({ userId }),
    queryKey: 'fetchUserRecipe',
    options: {
      method: 'GET',
    },
    transform: (body) => ({
      recipes: body,
    }),
    update: {
      recipes: (prev, next) => next,
    },
  };
};

export const insertRecipe = (params) => ({
  url: Api.insertRecipe(),
  queryKey: 'insertRecipe',
  options: {
    method: 'POST',
  },
  body: {
    ...params,
  },
  transform: (body) => ({
    recipes: body,
  }),
  update: {
    recipes: (prev, next) => {
      if (!next) return prev;
      if (!prev) return [next];
      return [next, ...prev];
    },
  },
});

export const deleteRecipe = (id) => ({
  url: Api.deleteRecipe({ id }),
  queryKey: 'deleteRecipe',
  options: {
    method: 'DELETE',
  },
  transform: (body) => ({
    recipes: body,
  }),
  update: {
    recipes: (prev) => {
      if (!prev) return [];
      const newRecipes = id ? prev.filter((p) => p.id !== id) : prev;
      return newRecipes;
    },
  },
});

export const fetchRecipesAssociatedWithCurrentUser = ({ userId }) => ({
  url: Api.fetchRecipesForUser(),
  queryKey: 'fetchRecipesForUser',
  options: {
    method: 'POST',
  },
  body: {
    userId,
  },
  transform: (body) => ({
    recipes: body,
  }),
  update: {
    recipes: (prev = [], next) => {
      if (!next) {
        return prev;
      }

      return [...prev, ...next];
    },
  },
});
