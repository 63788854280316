import React from 'react';
import styled from 'styled-components';

const PageHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 18px;
  margin: 2px;
`;

const Content = styled.div`
  margin: 8px;
`;

const Row = styled.div`
  margin: 4px;
`;

const Title = styled.div`
  font-size: 24px;
  color: #00000078;
`;

const PageHeader = ({ title,
  logo,
  action,
  content,
  extraContent }) => (
  <PageHeaderWrapper>
    {logo && <div className="logo">{logo}</div>}
    <Content>
      <Row>
        {title && <Title>{title}</Title>}
        {action && <div className="action">{action}</div>}
      </Row>
      <Row>
        {content && <div className="content">{content}</div>}
        {extraContent && <div className="extraContent">{extraContent}</div>}
      </Row>
    </Content>
  </PageHeaderWrapper>
);

export default PageHeader;
