import React, { PureComponent } from 'react';
import style from './ErrorBoundary.module.scss';

class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      this.logger.error({ error }, { info });
    }
    console.log({ error }, { info }); // eslint-disable-line
    this.setState({ error, info });
  }

  render() {
    const { hasError, error, info } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <div className={style.container}>
          <h1>That was not ideal!</h1>
          <span role="img" aria-label="broke" className={style.pooMoji}>
            💩
          </span>
          <div className={style.reactErrorContainer}>
            <div>{error ? JSON.stringify(error) : null}</div>
            <div>{info ? JSON.stringify(info) : null}</div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
