import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectIsAuthenticated } from './selectors';
import { compose } from './utils/utils';
// import Home from './pages/Home';
import NotFound from './pages/404';
// import ListPage from './pages/List';
import ReadOnlyList from './pages/ReadOnly';
// import Settings from './pages/Settings';
// import Login from './components/Login';
// import Logout from './components/Logout';
// import Signup from './components/Signup';
// import ForgotPassword from './components/ForgotPassword';
// import ChangePassword from './components/ChangePassword';
// import ResetPassword from './components/ResetPassword';
import ReferralRoute from './ReferralRoute';

const Home = React.lazy(() => import('./pages/Home'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Login = React.lazy(() => import('./components/Login'));
const Logout = React.lazy(() => import('./components/Logout'));
// const Signup = React.lazy(() => import('./components/Signup'));
const ForgotPassword = React.lazy(() => import('./components/ForgotPassword'));
const ChangePassword = React.lazy(() => import('./components/ChangePassword'));
const ResetPassword = React.lazy(() => import('./components/ResetPassword'));
const ListPage = React.lazy(() => import('./pages/List'));
const RecipesPage = React.lazy(() => import('./pages/Recipes'));
const AboutPage = React.lazy(() => import('./pages/About'));

const PrivateRoute = ({ children, isAuthenticated }) => {
  if (isAuthenticated) {
    return <>{children}</>;
  }
  return <Home />;
};

const Routes = ({ isAuthenticated }) => (
  <Switch>
    <Route exact path="/">
      <Home />
    </Route>
    <Route exact path="/login">
      <Login />
    </Route>
    <Route exact path="/logout">
      <Logout />
    </Route>
    {/* <Route exact path="/signup"><Signup /></Route> */}
    <Route exact path="/forgot-password">
      <ForgotPassword />
    </Route>
    <Route exact path="/change-password">
      <ChangePassword />
    </Route>
    <Route exact path="/reset-password">
      <ResetPassword />
    </Route>
    <Route exact path="/referral">
      <ReferralRoute />
    </Route>
    <Route path="/livelist/:readOnlyToken">
      <ReadOnlyList />
    </Route>
    <PrivateRoute isAuthenticated={isAuthenticated} path="/recipes">
      <RecipesPage />
    </PrivateRoute>
    <PrivateRoute isAuthenticated={isAuthenticated} path="/lists">
      <ListPage />
    </PrivateRoute>
    <PrivateRoute isAuthenticated={isAuthenticated} path="/account">
      <ListPage />
    </PrivateRoute>
    <PrivateRoute isAuthenticated={isAuthenticated} path="/settings">
      <Settings />
    </PrivateRoute>
    <PrivateRoute isAuthenticated={isAuthenticated} path="/about">
      <AboutPage />
    </PrivateRoute>
    <Route component={NotFound} />
  </Switch>
);

Routes.defaultProps = {
  isAuthenticated: null,
};

const mapStateToProps = (state) => ({
  isAuthenticated: selectIsAuthenticated(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(Routes);
