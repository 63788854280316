import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Button from 'antd/lib/button';
import config from './typeConfig';
import './index.scss';

class Exception extends React.PureComponent {
  static defaultProps = {
    backText: 'back to home',
    redirect: '/'
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleNav = () => {
    const { redirect, history } = this.props;
    history.push(redirect);
  };

  render() {
    const { className, backText, type, title, desc, img, actions } = this.props;
    const pageType = type in config ? type : '404';
    const clsString = classNames('exception', className);
    return (
      <div className={clsString}>
        <div className="imgBlock">
          <div className="imgEle" style={{ backgroundImage: `url(${img || config[pageType].img})` }} />
        </div>
        <div className="content">
          <h1>{title || config[pageType].title}</h1>
          <div className="desc">{desc || config[pageType].desc}</div>
          <div className="actions">
            {actions || (
              <Button type="primary" onClick={this.handleNav}>
                {backText}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Exception);
