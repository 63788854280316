import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spin from 'antd/lib/spin';
import { withRouter } from 'react-router-dom';
import { requestAsync } from 'redux-query';
import { checkAuth } from './redux/queries';
import Routes from './routes';
import { selectIsAuthenticated, selectQuerySuccess, CHECK_AUTH } from './selectors';
import { compose } from './utils';
import BasicLayout from './layouts/BasicLayout';

const AuthContainer = ({ checkAuth, isAuthenticated, checkAuthSuccess }) => {

  React.useEffect(() => {
    const token = localStorage.getItem('token');
    if (!isAuthenticated && token !== undefined) {
      checkAuth(token);
    }
  }, [isAuthenticated]);


  // while checkAuthSuccess has not evaluated as a result of actually checking the auth token
  // we should show a loading element instead of rendering the routes which will redirect us to
  // a non-private route mistakenly - the user is not unauthenticated after the auth process
  // but because it hasn't been allowed to run yet.
  // This is important for correctly following referral email links for users with valid tokens
  if (typeof checkAuthSuccess !== 'boolean') return <Spin spinning wrapperClassName="trigger" />;

  return (
    <BasicLayout>{checkAuthSuccess && <Routes isAuthenticated={isAuthenticated} />}</BasicLayout>
  );
};

AuthContainer.propTypes = {
  isAuthenticated: PropTypes.bool,
  checkAuthSuccess: PropTypes.bool
};

AuthContainer.defaultProps = {
  isAuthenticated: false,
  checkAuthSuccess: null
};

const mapStateToProps = state => ({
  isAuthenticated: selectIsAuthenticated(state),
  checkAuthSuccess: selectQuerySuccess(state, CHECK_AUTH)
});

const mapDispatchToProps = dispatch => ({
  checkAuth: token => dispatch(requestAsync(checkAuth(token)))
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(AuthContainer);
