import React from 'react';
import { connect } from 'react-redux';
import { mutateAsync } from 'redux-query';
import { withRouter } from 'react-router-dom';
import { compose } from '../utils/utils';
import GlobalHeader from '../components/GlobalHeader';
import style from './Header.module.scss';
import { selectCurrentUserEmail, selectCurrentUserId, selectIsAuthenticated } from '../selectors';
import { logout } from '../redux/queries';

const HeaderView = ({ history, dispatchLogout, currentUserEmail, isAuthenticated }) => {

  const handleMenuClick = ({ key }) => {
    if (key === 'account') {
      history.push('/account');
      return;
    }
    if (key === 'settings') {
      history.push('/settings');
      return;
    }
    if (key === 'logout') {
      dispatchLogout(currentUserEmail);
      localStorage.removeItem('token');
    }
  };

  return (
    <div className={style.fixedHeader}>
      <GlobalHeader
        // onNoticeClear={handleNoticeClear}
        onMenuClick={handleMenuClick}
        isAuthenticated={isAuthenticated}
      />
    </div>
  );
};


const mapStateToProps = state => ({
  currentUserEmail: selectCurrentUserEmail(state),
  userId: selectCurrentUserId(state),
  isAuthenticated: selectIsAuthenticated(state)
});

const mapDispatchToProps = dispatch => ({
  dispatchLogout: email => dispatch(mutateAsync(logout(email))),
  fetchNotices: type => dispatch(type)
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(HeaderView);
